// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebase: {
    // projectId: 'jay-dudhee',
    // appId: '1:280264084892:web:fd398a53a9681874310c59',
    // storageBucket: 'jay-dudhee.appspot.com',
    // apiKey: 'AIzaSyCijp5j0iqkRkxLGO3A5RvnZHcO7z5nZyU',
    // authDomain: 'jay-dudhee.firebaseapp.com',
    // messagingSenderId: '280264084892',
    // measurementId: 'G-B3T752S53J',
    apiKey: "AIzaSyBK4-M4ShJXSAeefb72uYjaO9GSeYWGx4Q",
    authDomain: "le-club-app.firebaseapp.com",
    databaseURL: "https://le-club-app-default-rtdb.firebaseio.com",
    projectId: "le-club-app",
    storageBucket: "le-club-app.appspot.com",
    messagingSenderId: "600713255665",
    appId: "1:600713255665:web:683d04d501bca2d02da750",
    measurementId: "G-0F5LE98407"
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
